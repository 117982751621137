import { Box } from "pws-design-system/design-system/";
import React, { ReactNode } from "react";
import { useMessages } from "../hooks/useMessages";
import { ThemeContainer, themes } from "../hooks/useTheme";
import { PressureTypeContainer } from "../hooks/stores/usePressureTypeStore";
import { TimeStoreContainer } from "../hooks/stores/useTimeStore";
import { UnitStoreContainer } from "../hooks/stores/useUnitStore";
import IsMetric from "../hooks/useIsMetric";
import { CSSReset, GlobalStyle, ThemeProvider } from "pws-design-system/design-system/";
import UsePreviewDataContainer from "../hooks/stores/usePreviewDataStore";
import { UseDateContainer } from "../hooks/stores/useDateStore";

interface LayoutProps {
  children: ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  const { theme } = ThemeContainer.useContainer();
  // const { name } = theme;
  return <Box color={theme.colors.text.base.primary}>{children}</Box>;
};

const ThemeProviderSetup = ({ children }: LayoutProps) => {
  const { theme } = ThemeContainer.useContainer();
  return (
    <ThemeProvider theme={theme || themes.light}>
      <CSSReset />
      <GlobalStyle />
      {children}
    </ThemeProvider>
  );
};

const Providers = ({ children }: LayoutProps) => {
  return (
    <PressureTypeContainer.Provider>
      <TimeStoreContainer.Provider>
        <UnitStoreContainer.Provider>
          <IsMetric.Provider>
            <ThemeContainer.Provider>
              <UsePreviewDataContainer.Provider>
                <UseDateContainer.Provider>
                  <ThemeProviderSetup>{children}</ThemeProviderSetup>
                </UseDateContainer.Provider>
              </UsePreviewDataContainer.Provider>
            </ThemeContainer.Provider>
          </IsMetric.Provider>
        </UnitStoreContainer.Provider>
      </TimeStoreContainer.Provider>
    </PressureTypeContainer.Provider>
  );
};

export default ({ children }: LayoutProps) => {
  useMessages();
  return <Layout>{children}</Layout>;
};

export { Providers };
