export let isMetricStore = false;
import _get from "lodash.get";
import { useStaticQuery, graphql } from "gatsby";

let messageStore: any = null;

export function useMessages() {
  graphql`
    fragment AutoSuggestMessages on PrismicMessagesDataType {
      autosuggest_placeholder
      autosuggest_no_stations
      autosuggest_no_locations
      autosuggest_station_map_link_text
    }
  `;

  graphql`
    fragment StationPageMetaData on PrismicMessagesDataType {
      dashboard_station_title
      dashboard_station_meta_description
      dashboard_location_title
      dashboard_location_meta_description
      forecast_station_title
      forecast_station_meta_description
      forecast_location_title
      forecast_location_meta_description
      map_location_title
      map_location_meta_description
      map_station_title
      map_station_meta_description
      historical_location_title
      historical_location_meta_description
      historical_station_title
      historical_station_meta_description
    }
  `;

  graphql`
    fragment AuthenticationPageMetaData on PrismicMessagesDataType {
      login_page_title
      login_page_meta_description
      registration_page_title
      registration_page_description
      forgot_username_page_title
      forgot_username_meta_description
      forgot_password_page_title
      forgot_password_meta_description
    }
  `;

  const prismicData = useStaticQuery(
    graphql`
      query PrismicMessages {
        allPrismicMessages {
          nodes {
            data {
              ...AutoSuggestMessages
              ...StationPageMetaData
              ...AuthenticationPageMetaData
              global_meta_title_suffix
              form_subjects {
                label
                value
              }
              station_map_page_title
              station_map_meta_description
            }
          }
        }
      }
    `
  );
  const messages = _get(prismicData, "allPrismicMessages.nodes[0].data", null);
  messageStore = messages;
  return messages;
}

export { messageStore };
