type GenericObject = { [key: string]: any };
import * as yup from "yup";
import queryString from "query-string";
import { isBrowser } from "../../../../utils";

export default abstract class QueryStringStore {
  abstract keys: string[];
  abstract schema: yup.ObjectSchema;

  protected parseQueryString() {
    if (isBrowser() === false) {
      return;
    }
    const parsed = queryString.parse(location.search);
    return parsed;
  }

  protected toQueryString(vals: { [key: string]: any }) {
    if (isBrowser() === false) {
      return;
    }
    const params = new URLSearchParams(window.location.search);
    Object.keys(vals).forEach(key => {
      const val = vals[key];
      params.set(key, val);
    });

    window.history.replaceState(
      {},
      "",
      decodeURIComponent(`${window.location.pathname}?${params}`)
    );
  }
}
