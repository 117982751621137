import { useState } from "react";
import { createContainer } from "unstated-next";
import QueryStringStore from "./QueryStringStore";
import * as yup from "yup";
import { numberOfDaysInAMonth } from "../../../../utils";
import { CardPeriod } from "../../../../types/enums";
import { convertToLocalTime, convertToTimeZone, parseFromTimeZone } from "date-fns-timezone";
import { format, formatRelative, startOfDay } from "date-fns";
import { padStart } from "lodash";

enum DateParamKeys {
  Day = "day",
  Month = "month",
  Year = "year"
}

const TIME_PARAM_KEY = "timespan";

type TParams = {
  timespan: DateParamKeys;
  date: Date;
};

/**
 * Serializes and validates dates for the historical views to the url as search params.
 * @class DateStore
 * @extends {QueryStringStore}
 */
class DateStore extends QueryStringStore {
  keys = [TIME_PARAM_KEY, ...Object.values(DateParamKeys)];
  schema = yup.object().shape({
    timespan: yup
      .string()
      .oneOf([DateParamKeys.Day, DateParamKeys.Month, DateParamKeys.Year])
      .required(),
    date: yup.string()
  });

  timezone = "Africa/Abidjan";

  get date() {
    return this.values.date;
  }

  validate(values: any): TParams {
    try {
      const validated = this.schema.validateSync(values) as TParams;
      return validated;
    } catch (e) {
      const timezoned = convertToTimeZone(new Date(), { timeZone: this.timezone });
      const date = format(timezoned, "yyyy-MM-dd");

      return {
        timespan: DateParamKeys.Day,
        date
      };
    }
  }

  get values() {
    const values = this.parseQueryString();
    const validatedValues = this.validate(values);
    return validatedValues;
  }

  set values(vals: TParams) {
    try {
      const validated = this.schema.validateSync(vals);
      const timezoned = format(
        convertToTimeZone(validated.date, { timeZone: this.timezone }),
        "yyyy-MM-dd"
      );
      const queryValues = {
        ...validated,
        date: timezoned
      };

      this.toQueryString(queryValues);
    } catch (e) {
      console.error(e);
    }
  }
}

export const dateStore = new DateStore();

/**
 * Transforms data to the data needed for the search params. Saves data to state
 * to cause rerenders on changes.
 */
const useDate = () => {
  const mapPeriodToDateParam: { [key: string]: DateParamKeys } = {
    [CardPeriod.Daily]: DateParamKeys.Day,
    [CardPeriod.Monthly]: DateParamKeys.Month,
    [CardPeriod.Yearly]: DateParamKeys.Year
  };

  const mapDateParamToCardPeriod: { [key: string]: CardPeriod } = {
    [DateParamKeys.Day]: CardPeriod.Daily,
    [DateParamKeys.Month]: CardPeriod.Monthly,
    [DateParamKeys.Year]: CardPeriod.Yearly
  };

  const [date, setDateState] = useState(null);
  const [period, setPeriodState] = useState(mapDateParamToCardPeriod[dateStore.values.timespan]);

  const updateStore = (date: Date, period: CardPeriod) => {
    dateStore.values = {
      timespan: mapPeriodToDateParam[period],
      date
    };
  };

  const setDate = (date: Date) => {
    updateStore(date, period);
    setDateState(date);
  };

  const setPeriod = (period: CardPeriod) => {
    updateStore(date, period);
    setPeriodState(period);
  };

  const setTimezone = (timezone: string) => {
    dateStore.timezone = timezone;
    setDateState(dateStore.date);
  };

  return { date, dateStore, period, setDate, setPeriod, setTimezone };
};

export const UseDateContainer = createContainer(useDate);
